import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { concatMap, EMPTY } from 'rxjs';
import { BaseState } from './base';
import { CityService } from '../service/api/city/city.service';
export interface city {
  list: any[];
}

export class FetchCity {
  static readonly type = '[CityState] FetchCity';
}

export class FetchCityById {
  static readonly type = '[CityState] FetchCityById';
  constructor(
    public payload: {
      cityId: string;
      stateId: string;
      countryId: string;
    }
  ) {}
}

@State<city>({
  name: 'city',
  defaults: {
    list: [],
  },
})
@Injectable()
export class CityState extends BaseState implements NgxsOnInit {
  constructor(private cityService: CityService) {
    super();
  }
  ngxsOnInit(ctx: StateContext<city>): void {}

  @Selector()
  static getList(state: city): any[] {
    return state.list;
  }

  @Action(FetchCity)
  fetchCity(states: StateContext<city>) {
    return this.cityService.getCityList().pipe(
      concatMap((res) => {
        console.log(res);
        states.patchState({
          list: res.message.cityList,
        });
        return EMPTY;
      })
    );
  }

  @Action(FetchCityById)
  fetchCityById(states: StateContext<city>, action: FetchCityById) {
    return this.cityService.getCityListById(action.payload).pipe(
      concatMap((res) => {
        console.log(res);
        states.patchState({
          list: res.message.cityList,
        });
        return EMPTY;
      })
    );
  }
}
