import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  login(payload: any) {
    return this.http.post(environment.host + '/auth.php', {
      gofor: 'login',
      ...payload,
    });
  }

  fetchCountrylist() {
    return this.http.get(environment.host + '/api.php', {
      params: new HttpParams({
        fromObject: {
          gofor: 'countrieslist',
        },
      }),
    });
  }

  fetchStatelist(countryId: string) {
    return this.http.get(environment.host + '/api.php', {
      params: new HttpParams({
        fromObject: {
          gofor: 'stateslist',
          country_id: countryId,
        },
      }),
    });
  }

  fetchCitylist(stateId: string) {
    return this.http.get(environment.host + '/api.php', {
      params: new HttpParams({
        fromObject: {
          gofor: 'citieslist',
          state_id: stateId,
        },
      }),
    });
  }

  getFile(payload: any): Observable<any> {
    let formdata = new FormData();
    for (let key in payload) {
      formdata.append(key, payload[key]);
    }
    return this.http.post(environment.host + '/api.php', formdata, {
      responseType: 'blob',
    });
  }

  deleteFile(payload: any): Observable<any> {
    return this.http.post(environment.host + '/api.php', payload);
  }
}
