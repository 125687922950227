import { Injectable } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { append, patch } from '@ngxs/store/operators';
import _ from 'lodash';
import { EMPTY } from 'rxjs';

export interface jobordermodel {
  list: any[];
  jobList: any[];
  item: any;
  category: baseSelect[];
  projectType: baseSelect[];
  sourceType: baseSelect[];
  age: string[];
  visacategory: baseSelect[];
  vendor: baseSelect[];
  medicalType: baseSelect[];
  mode: baseSelect[];
  optSelect: baseSelect[];
  ticketType: baseSelect[];
}

export interface baseSelect {
  label: string;
  value: string;
}

export class FetchJobOrderList {
  static readonly type = '[JobOrderState] FetchJobOrderList';
  constructor(public id?: string) {}
}

export class CreateJobOrder {
  static readonly type = '[JobOrderState] CreateJobOrder';
  constructor(public payload: any) {}
}

export class FetchJobOrderById {
  static readonly type = '[JobOrderState] FetchJobOrderById';
  constructor(public id: any) {}
}

@State<jobordermodel>({
  name: 'joborder',
  defaults: {
    list: [
      {
        client_id: '1',
        client_reference: '1',
        delivery_date: '09/09/2024',
        interview_date: '08/09/2024',
      },
    ],
    jobList: [],
    item: null,
    category: [
      { label: 'Civil', value: '1' },
      { label: 'Mechanical', value: '2' },
    ],
    projectType: [],
    sourceType: [
      { label: 'Local', value: '1' },
      { label: 'Overseas', value: '2' },
    ],
    age: _.range(18, 61).map((el) => el.toString()),
    visacategory: [],
    vendor: [
      {
        label: 'Associate',
        value: '1',
      },
      {
        label: 'Candidate',
        value: '2',
      },
      {
        label: 'Client',
        value: '3',
      },
    ],
    medicalType: [
      {
        label: 'General',
        value: '1',
      },
      {
        label: 'Gamca',
        value: '2',
      },
    ],
    mode: [
      {
        label: 'Interview',
        value: '1',
      },
      {
        label: 'Online',
        value: '2',
      },
      {
        label: 'CV',
        value: '3',
      },
      {
        label: 'Face to Face Interview by Client',
        value: '4',
      },
      {
        label: 'Interview by SK',
        value: '5',
      },
    ],
    ticketType: [
      {
        label: '1 Year',
        value: '1',
      },
      {
        label: '2 Year',
        value: '2',
      },
    ],
    optSelect: [
      {
        label: 'Included',
        value: '1',
      },
      {
        label: 'Excluded',
        value: '2',
      },
    ],
  },
})
@Injectable()
export class JobOrderState {
  constructor() {}

  @Selector()
  static getList(state: jobordermodel): any[] {
    return state.list;
  }

  @Selector()
  static getViewList(state: jobordermodel): any[] {
    return state.item;
  }

  @Selector()
  static getCategory(state: jobordermodel): baseSelect[] {
    return state.category;
  }

  @Selector()
  static getProjectType(state: jobordermodel): baseSelect[] {
    return state.projectType;
  }

  @Selector()
  static getSourceType(state: jobordermodel): baseSelect[] {
    return state.sourceType;
  }

  @Selector()
  static getAge(state: jobordermodel): string[] {
    return state.age;
  }

  @Selector()
  static getVisaCategory(state: jobordermodel): baseSelect[] {
    return state.visacategory;
  }

  @Selector()
  static getVendors(state: jobordermodel): baseSelect[] {
    return state.vendor;
  }

  @Selector()
  static getMedicalType(state: jobordermodel): baseSelect[] {
    return state.medicalType;
  }

  @Selector()
  static getMode(state: jobordermodel): baseSelect[] {
    return state.mode;
  }

  @Selector()
  static getOptSelect(state: jobordermodel): baseSelect[] {
    return state.optSelect;
  }

  @Selector()
  static getTicketType(state: jobordermodel): baseSelect[] {
    return state.ticketType;
  }

  @Selector()
  static getJobList(state: jobordermodel): any[] {
    return state.jobList;
  }

  @Action(CreateJobOrder)
  createJobOrder(states: StateContext<jobordermodel>, action: CreateJobOrder) {
    states.setState(
      patch({
        list: append([action.payload]),
      })
    );

    return states.dispatch([new Navigate(['/', 'job-order'])]);
  }

  @Action(FetchJobOrderById)
  fetchJobOrderbyId(
    states: StateContext<jobordermodel>,
    action: FetchJobOrderById
  ) {
    states.patchState({
      item: {
        client_reference: '1',
        client_id: '1',
        job: [
          {
            name: 'Civil Engineer',
            country: '1',
            state: '1',
            city: '1',
            project_type: '1',
            industry: '1',
            category: '1',
            job_category: '1',
            quantity: '1',
            source_type: '2',
            source_country: '1',
            required_exp: '1',
            min_age: '24',
            max_age: '30',
            visa_category: '1',
            visa_cost: '1',
            medical_type: '1',
            ticket: '1',
            interviewby: '1',
            mode: '1',
            proposed_salary: '1',
            food: '1',
            transportation: '1',
            medical_insurance: '1',
            life_insurance: '1',
            working_hour: '6',
            over_time: '1',
            laundry: '1',
            ticket_type: '2',
          },
        ],
        interview_date: '02/02/2024',
        delivery_date: '04/02/2024',
      },
    });

    return EMPTY;
  }
}
