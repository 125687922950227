import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { concatMap, EMPTY } from 'rxjs';
import { BaseState } from './base';
import { CityService } from '../service/api/city/city.service';
import { StateService } from '../service/api/state/state.service';
export interface state {
  list: any[];
}

export class FetchState {
  static readonly type = '[StateState] FetchState';
}

@State<state>({
  name: 'state',
  defaults: {
    list: [],
  },
})
@Injectable()
export class StateState extends BaseState implements NgxsOnInit {
  constructor(private stateService: StateService) {
    super();
  }
  ngxsOnInit(ctx: StateContext<state>): void {}

  @Selector()
  static getList(state: state): any[] {
    return state.list;
  }

  @Action(FetchState)
  fetchType(states: StateContext<state>) {
    return this.stateService.getStateList().pipe(
      concatMap((res) => {
        console.log(res);
        states.patchState({
          list: res.message.stateList,
        });
        return EMPTY;
      })
    );
  }
}
