import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { CompanyService } from '../service/api/company/company.service';
import { concatMap, EMPTY } from 'rxjs';
import { BaseState } from './base';
import { IndustryService } from '../service/api/industry/industry.service';
import { SubIndustryService } from '../service/api/sub-industry/sub-industry.service';
export interface subindustry {
  list: any[];
}

export class FetchSubIndustry {
  static readonly type = '[SubIndustryState] FetchSubIndustry';
}

@State<subindustry>({
  name: 'sub_industry',
  defaults: {
    list: [],
  },
})
@Injectable()
export class SubIndustryState extends BaseState implements NgxsOnInit {
  constructor(private subindustryService: SubIndustryService) {
    super();
  }
  ngxsOnInit(ctx: StateContext<any>): void {
    // ctx.dispatch(new FetchIndustry());
  }

  @Selector()
  static getList(state: subindustry): any[] {
    return state.list;
  }

  @Action(FetchSubIndustry)
  fetchType(states: StateContext<subindustry>) {
    return this.subindustryService.getSubIndustryList().pipe(
      concatMap((res) => {
        console.log(res);
        states.patchState({
          list: res.message.subIndustryList,
        });
        return EMPTY;
      })
    );
  }
}
