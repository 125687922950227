import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ApiService } from '../service/api/api.service';
import { EMPTY, map } from 'rxjs';
import { append, patch } from '@ngxs/store/operators';
import _ from 'lodash';
import { baseSelect } from './joborder';

export interface appstatemodel {
  countries: any[];
  states: any[];
  cities: any[];
  jobcategory: baseSelect[];
  yearList: baseSelect[];
  monthList: baseSelect[];
  yesnoStatus: baseSelect[];
  currencyType: baseSelect[];
  industry: baseSelect[];
  feedbackType: baseSelect[];
}

export class FetchCountryList {
  static readonly type = '[AppState] FetchCountryList';
}
export class FetchStateList {
  static readonly type = '[AppState] FetchStateList';
  constructor(public countryId: string) {}
}
export class FetchCityList {
  static readonly type = '[AppState] FetchCityList';
  constructor(public stateId: string) {}
}

@State<appstatemodel>({
  name: 'app',
  defaults: {
    countries: [],
    states: [],
    cities: [],
    jobcategory: [
      {
        label: 'IT',
        value: '1',
      },
    ],
    yearList: [
      { label: 'No', value: '0' },
      ..._.range(1, 50).map((el) => {
        return { label: el.toString(), value: el.toString() };
      }),
    ],
    monthList: [
      { label: 'No', value: '0' },
      ..._.range(1, 12).map((el) => {
        return { label: el.toString(), value: el.toString() };
      }),
    ],
    yesnoStatus: [
      { label: 'Yes', value: '1' },
      { label: 'No', value: '0' },
    ],
    currencyType: [
      { value: 'AFN', label: 'Afghan Afghani' },
      { value: 'ALL', label: 'Albanian Lek' },
      { value: 'DZD', label: 'Algerian Dinar' },
      { value: 'AOA', label: 'Angolan Kwanza' },
      { value: 'ARS', label: 'Argentine Peso' },
      { value: 'AMD', label: 'Armenian Dram' },
      { value: 'AWG', label: 'Aruban Florin' },
      { value: 'AUD', label: 'Australian Dollar' },
      { value: 'AZN', label: 'Azerbaijani Manat' },
      { value: 'BSD', label: 'Bahamian Dollar' },
      { value: 'BHD', label: 'Bahraini Dinar' },
      { value: 'BDT', label: 'Bangladeshi Taka' },
      { value: 'BBD', label: 'Barbadian Dollar' },
      { value: 'BYR', label: 'Belarusian Ruble' },
      { value: 'BEF', label: 'Belgian Franc' },
      { value: 'BZD', label: 'Belize Dollar' },
      { value: 'BMD', label: 'Bermudan Dollar' },
      { value: 'BTN', label: 'Bhutanese Ngultrum' },
      { value: 'BTC', label: 'Bitcoin' },
      { value: 'BOB', label: 'Bolivian Boliviano' },
      { value: 'BAM', label: 'Bosnia-Herzegovina Convertible Mark' },
      { value: 'BWP', label: 'Botswanan Pula' },
      { value: 'BRL', label: 'Brazilian Real' },
      { value: 'GBP', label: 'British Pound Sterling' },
      { value: 'BND', label: 'Brunei Dollar' },
      { value: 'BGN', label: 'Bulgarian Lev' },
      { value: 'BIF', label: 'Burundian Franc' },
      { value: 'KHR', label: 'Cambodian Riel' },
      { value: 'CAD', label: 'Canadian Dollar' },
      { value: 'CVE', label: 'Cape Verdean Escudo' },
      { value: 'KYD', label: 'Cayman Islands Dollar' },
      { value: 'XOF', label: 'CFA Franc BCEAO' },
      { value: 'XAF', label: 'CFA Franc BEAC' },
      { value: 'XPF', label: 'CFP Franc' },
      { value: 'CLP', label: 'Chilean Peso' },
      { value: 'CNY', label: 'Chinese Yuan' },
      { value: 'COP', label: 'Colombian Peso' },
      { value: 'KMF', label: 'Comorian Franc' },
      { value: 'CDF', label: 'Congolese Franc' },
      { value: 'CRC', label: 'Costa Rican Colón' },
      { value: 'HRK', label: 'Croatian Kuna' },
      { value: 'CUC', label: 'Cuban Convertible Peso' },
      { value: 'CZK', label: 'Czech Republic Koruna' },
      { value: 'DKK', label: 'Danish Krone' },
      { value: 'DJF', label: 'Djiboutian Franc' },
      { value: 'DOP', label: 'Dominican Peso' },
      { value: 'XCD', label: 'East Caribbean Dollar' },
      { value: 'EGP', label: 'Egyptian Pound' },
      { value: 'ERN', label: 'Eritrean Nakfa' },
      { value: 'EEK', label: 'Estonian Kroon' },
      { value: 'ETB', label: 'Ethiopian Birr' },
      { value: 'EUR', label: 'Euro' },
      { value: 'FKP', label: 'Falkland Islands Pound' },
      { value: 'FJD', label: 'Fijian Dollar' },
      { value: 'GMD', label: 'Gambian Dalasi' },
      { value: 'GEL', label: 'Georgian Lari' },
      { value: 'DEM', label: 'German Mark' },
      { value: 'GHS', label: 'Ghanaian Cedi' },
      { value: 'GIP', label: 'Gibraltar Pound' },
      { value: 'GRD', label: 'Greek Drachma' },
      { value: 'GTQ', label: 'Guatemalan Quetzal' },
      { value: 'GNF', label: 'Guinean Franc' },
      { value: 'GYD', label: 'Guyanaese Dollar' },
      { value: 'HTG', label: 'Haitian Gourde' },
      { value: 'HNL', label: 'Honduran Lempira' },
      { value: 'HKD', label: 'Hong Kong Dollar' },
      { value: 'HUF', label: 'Hungarian Forint' },
      { value: 'ISK', label: 'Icelandic Króna' },
      { value: 'INR', label: 'Indian Rupee' },
      { value: 'IDR', label: 'Indonesian Rupiah' },
      { value: 'IRR', label: 'Iranian Rial' },
      { value: 'IQD', label: 'Iraqi Dinar' },
      { value: 'ILS', label: 'Israeli New Sheqel' },
      { value: 'ITL', label: 'Italian Lira' },
      { value: 'JMD', label: 'Jamaican Dollar' },
      { value: 'JPY', label: 'Japanese Yen' },
      { value: 'JOD', label: 'Jordanian Dinar' },
      { value: 'KZT', label: 'Kazakhstani Tenge' },
      { value: 'KES', label: 'Kenyan Shilling' },
      { value: 'KWD', label: 'Kuwaiti Dinar' },
      { value: 'KGS', label: 'Kyrgystani Som' },
      { value: 'LAK', label: 'Laotian Kip' },
      { value: 'LVL', label: 'Latvian Lats' },
      { value: 'LBP', label: 'Lebanese Pound' },
      { value: 'LSL', label: 'Lesotho Loti' },
      { value: 'LRD', label: 'Liberian Dollar' },
      { value: 'LYD', label: 'Libyan Dinar' },
      { value: 'LTL', label: 'Lithuanian Litas' },
      { value: 'MOP', label: 'Macanese Pataca' },
      { value: 'MKD', label: 'Macedonian Denar' },
      { value: 'MGA', label: 'Malagasy Ariary' },
      { value: 'MWK', label: 'Malawian Kwacha' },
      { value: 'MYR', label: 'Malaysian Ringgit' },
      { value: 'MVR', label: 'Maldivian Rufiyaa' },
      { value: 'MRO', label: 'Mauritanian Ouguiya' },
      { value: 'MUR', label: 'Mauritian Rupee' },
      { value: 'MXN', label: 'Mexican Peso' },
      { value: 'MDL', label: 'Moldovan Leu' },
      { value: 'MNT', label: 'Mongolian Tugrik' },
      { value: 'MAD', label: 'Moroccan Dirham' },
      { value: 'MZM', label: 'Mozambican Metical' },
      { value: 'MMK', label: 'Myanmar Kyat' },
      { value: 'NAD', label: 'Namibian Dollar' },
      { value: 'NPR', label: 'Nepalese Rupee' },
      { value: 'ANG', label: 'Netherlands Antillean Guilder' },
      { value: 'TWD', label: 'New Taiwan Dollar' },
      { value: 'NZD', label: 'New Zealand Dollar' },
      { value: 'NIO', label: 'Nicaraguan Córdoba' },
      { value: 'NGN', label: 'Nigerian Naira' },
      { value: 'KPW', label: 'North Korean Won' },
      { value: 'NOK', label: 'Norwegian Krone' },
      { value: 'OMR', label: 'Omani Rial' },
      { value: 'PKR', label: 'Pakistani Rupee' },
      { value: 'PAB', label: 'Panamanian Balboa' },
      { value: 'PGK', label: 'Papua New Guinean Kina' },
      { value: 'PYG', label: 'Paraguayan Guarani' },
      { value: 'PEN', label: 'Peruvian Nuevo Sol' },
      { value: 'PHP', label: 'Philippine Peso' },
      { value: 'PLN', label: 'Polish Zloty' },
      { value: 'QAR', label: 'Qatari Rial' },
      { value: 'RON', label: 'Romanian Leu' },
      { value: 'RUB', label: 'Russian Ruble' },
      { value: 'RWF', label: 'Rwandan Franc' },
      { value: 'SVC', label: 'Salvadoran Colón' },
      { value: 'WST', label: 'Samoan Tala' },
      { value: 'SAR', label: 'Saudi Riyal' },
      { value: 'RSD', label: 'Serbian Dinar' },
      { value: 'SCR', label: 'Seychellois Rupee' },
      { value: 'SLL', label: 'Sierra Leonean Leone' },
      { value: 'SGD', label: 'Singapore Dollar' },
      { value: 'SKK', label: 'Slovak Koruna' },
      { value: 'SBD', label: 'Solomon Islands Dollar' },
      { value: 'SOS', label: 'Somali Shilling' },
      { value: 'ZAR', label: 'South African Rand' },
      { value: 'KRW', label: 'South Korean Won' },
      { value: 'XDR', label: 'Special Drawing Rights' },
      { value: 'LKR', label: 'Sri Lankan Rupee' },
      { value: 'SHP', label: 'St. Helena Pound' },
      { value: 'SDG', label: 'Sudanese Pound' },
      { value: 'SRD', label: 'Surinamese Dollar' },
      { value: 'SZL', label: 'Swazi Lilangeni' },
      { value: 'SEK', label: 'Swedish Krona' },
      { value: 'CHF', label: 'Swiss Franc' },
      { value: 'SYP', label: 'Syrian Pound' },
      { value: 'STD', label: 'São Tomé and Príncipe Dobra' },
      { value: 'TJS', label: 'Tajikistani Somoni' },
      { value: 'TZS', label: 'Tanzanian Shilling' },
      { value: 'THB', label: 'Thai Baht' },
      { value: 'TOP', label: "Tongan pa'anga" },
      { value: 'TTD', label: 'Trinidad & Tobago Dollar' },
      { value: 'TND', label: 'Tunisian Dinar' },
      { value: 'TRY', label: 'Turkish Lira' },
      { value: 'TMT', label: 'Turkmenistani Manat' },
      { value: 'UGX', label: 'Ugandan Shilling' },
      { value: 'UAH', label: 'Ukrainian Hryvnia' },
      { value: 'AED', label: 'United Arab Emirates Dirham' },
      { value: 'UYU', label: 'Uruguayan Peso' },
      { value: 'USD', label: 'US Dollar' },
      { value: 'UZS', label: 'Uzbekistan Som' },
      { value: 'VUV', label: 'Vanuatu Vatu' },
      { value: 'VEF', label: 'Venezuelan Bolívar' },
      { value: 'VND', label: 'Vietnamese Dong' },
      { value: 'YER', label: 'Yemeni Rial' },
      { value: 'ZMK', label: 'Zambian Kwacha' },
    ],
    industry: [],
    feedbackType: [
      { label: 'Not Suitable', value: '0' },
      { label: 'Suitable', value: '1' },
    ],
  },
})
@Injectable()
export class AppState {
  constructor(private apiService: ApiService) {}

  @Selector()
  static getCountryList(state: appstatemodel): any[] {
    return state.countries.map((el) => {
      return {
        name: el.name,
        id: el.id,
      };
    });
  }

  @Selector()
  static getStateList(state: appstatemodel): any[] {
    return state.states.map((el) => {
      return {
        name: el.name,
        id: el.id,
      };
    });
  }

  @Selector()
  static getCityList(state: appstatemodel): any[] {
    return state.cities.map((el) => {
      return {
        name: el.name,
        id: el.id,
      };
    });
  }

  @Selector()
  static getJobCategory(state: appstatemodel): baseSelect[] {
    return state.jobcategory;
  }

  @Selector()
  static getYearList(state: appstatemodel): baseSelect[] {
    return state.yearList;
  }

  @Selector()
  static getMonthList(state: appstatemodel): baseSelect[] {
    return state.monthList;
  }

  @Selector()
  static getYesNoStatus(state: appstatemodel): baseSelect[] {
    return state.yesnoStatus;
  }

  @Selector()
  static getYesNo2Stataus(state: appstatemodel): baseSelect[] {
    return [
      ...state.yesnoStatus,
      {
        label: 'Not Applicable',
        value: '2',
      },
    ];
  }

  @Selector()
  static getCurrencyType(state: appstatemodel): baseSelect[] {
    return state.currencyType;
  }

  @Selector()
  static getIndustry(state: appstatemodel): baseSelect[] {
    return state.industry;
  }

  @Selector()
  static getFeedBackType(state: appstatemodel): baseSelect[] {
    return state.feedbackType;
  }

  @Action(FetchCountryList, { cancelUncompleted: false })
  fetchCOuntryList(
    states: StateContext<appstatemodel>,
    action: FetchCountryList
  ) {
    return this.apiService.fetchCountrylist().pipe(
      map((res: any) => {
        states.setState(
          patch({
            countries: res,
          })
        );
      })
    );
  }

  @Action(FetchStateList, { cancelUncompleted: false })
  fetchStateList(states: StateContext<appstatemodel>, action: FetchStateList) {
    return this.apiService.fetchStatelist(action.countryId).pipe(
      map((res: any) => {
        states.setState(
          patch({
            states: res,
          })
        );
      })
    );
  }

  @Action(FetchCityList, { cancelUncompleted: false })
  fetchCityList(states: StateContext<appstatemodel>, action: FetchCityList) {
    return this.apiService.fetchCitylist(action.stateId).pipe(
      map((res: any) => {
        states.setState(
          patch({
            cities: res,
          })
        );
      })
    );
  }
}
