import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SubIndustryService {
  constructor(private http: HttpClient) {}

  getSubIndustryList() {
    return this.http.post<any>(environment.host + '/sub_industry.php', {
      gofor: 'allSubIndustries',
    });
  }
}
