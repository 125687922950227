import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CityService {
  constructor(private http: HttpClient) {}

  getCityList() {
    return this.http.post<any>(environment.host + '/city.php', {
      gofor: 'allcities',
    });
  }

  getCityListById(payload: {
    cityId: string;
    stateId: string;
    countryId: string;
  }) {
    return this.http.post<any>(environment.host + '/city.php', {
      gofor: 'cityById',
      body: {
        ...payload,
      },
    });
  }
}
