import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, concatMap, EMPTY } from 'rxjs';
import { ClientService } from '../service/api/client/client.service';

export interface clientmodel {
  item: any;
  list: any[];
}

export class AddClient {
  static readonly type = '[AddClientList] ClientState';
}

export class EditClient {
  static readonly type = '[EditClientList] ClientState';
}

export class FetchClients {
  static readonly type = '[FetchClientList] ClientState';
}

export class FetchClientById {
  static readonly type = '[FetchClientById] ClientState';
  constructor(public id: string) {}
}

export class DeleteClient {
  static readonly type = '[EditClientList] ClientState';
}

@State<clientmodel>({
  name: 'client',
  defaults: {
    list: [],
    item: null,
  },
})
@Injectable()
export class ClientState {
  constructor(private clientService: ClientService) {}

  @Selector()
  static getList(state: clientmodel): any[] {
    return state.list;
  }

  @Selector()
  static getItem(state: clientmodel): any {
    return state.item;
  }

  @Action(AddClient)
  addClient(states: StateContext<clientmodel>, action: AddClient) {
    return this.clientService.addClient(action).pipe(
      concatMap((res) => {
        console.log(res);
        return EMPTY;
      }),
      catchError((error) => {
        console.log(error);
        return EMPTY;
      })
    );
  }

  @Action(EditClient)
  editClient() {}

  @Action(FetchClientById)
  fetchClientById(states: StateContext<clientmodel>, action: FetchClientById) {
    return EMPTY;
  }

  @Action(DeleteClient)
  deletClient() {}
}
