import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { concatMap, EMPTY } from 'rxjs';
import { BaseState } from './base';
import { CountryService } from '../service/api/country/country.service';
export interface country {
  list: any[];
}

export class Fetchcountry {
  static readonly type = '[countryState] Fetchcountry';
}

@State<country>({
  name: 'country',
  defaults: {
    list: [],
  },
})
@Injectable()
export class CountryState extends BaseState implements NgxsOnInit {
  constructor(private countryService: CountryService) {
    super();
  }
  ngxsOnInit(ctx: StateContext<country>): void {}

  @Selector()
  static getList(state: country): any[] {
    return state.list;
  }

  @Action(Fetchcountry)
  fetchType(states: StateContext<country>) {
    return this.countryService.getCountryList().pipe(
      concatMap((res: any) => {
        console.log(res);
        states.patchState({
          list: res.message.countryList,
        });
        return EMPTY;
      })
    );
  }
}
