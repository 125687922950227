import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export interface GridService {
  // Column Management
  setColumns(columns: any[]): void;
  getColumns(): any[];

  // Data Management
  // setData(data: any[]): void;
  // getData(): any[];

  setApiUrl(url: string): void;
  setApiPayload(payload: any): void;

  // Pagination
  setPagination(pageSize: number, currentPage: number): void;
  getPagination(): {
    pageSize: number;
    currentPage: number;
    totalPages: number;
    firstIndex: number;
    lastIndex: number;
    totalCount: number;
  };

  // Fetch data with pagination and filters
  fetchData(apiUrl: string): Observable<any>; // New method to fetch data from the API

  // Filtering
  setFilter(filter: any): void;
  getFilter(): any;

  // Searching
  setSearchText(searchText: string): void;
  getSearchText(): string;

  // Sorting
  setSort(sortField: string, sortDirection: 'asc' | 'desc'): void;
  getSort(): { sortField: string; sortDirection: 'asc' | 'desc' } | null;

  getPages(): number[];
}

export const GRID_SERVICE = new InjectionToken<GridService>('GridService');
