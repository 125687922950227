import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  constructor(private http: HttpClient) {}

  getStateList() {
    return this.http.post<any>(environment.host + '/state.php', {
      gofor: 'allstates',
    });
  }
}
