import { Injectable } from '@angular/core';
import { Selector, State } from '@ngxs/store';

export interface associatemodel {
  list: any[];
}

@State<associatemodel>({
  name: 'associate',
  defaults: {
    list: [
      {
        id: '1',
        name: 'Arun Raj',
      },
    ],
  },
})
@Injectable()
export class AssociateState {
  constructor() {}

  @Selector()
  static getlist(state: associatemodel): any[] {
    return state.list;
  }
}
