import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  constructor(private http: HttpClient) {}

  // Add a new client
  addClient(clientData: any): Observable<any> {
    return this.http.post<any>(environment.host, clientData);
  }

  // Get a client by ID
  getClientById(clientId: string): Observable<any> {
    return this.http.get<any>(environment.host);
  }

  // Update an existing client
  updateClient(clientId: string, clientData: any): Observable<any> {
    return this.http.put<any>(environment.host, clientData);
  }

  // Get all clients with optional filtering and pagination
  getAllClients(payload: any): Observable<any[]> {
    return this.http.post<any[]>(environment.host, {
      gofor: 'getAllClients',
      ...payload,
    });
  }

  // Error handling method
  private handleError(error: any): Observable<never> {
    console.error('An error occurred:', error);
    // Optionally, you could transform the error here
    throw error; // Re-throw the error for further handling
  }
}
